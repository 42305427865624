import React, { useContext, useState, useEffect } from "react";
import { Box, Button, Container, Grid, LinearProgress, Tab, Tabs, Typography, Alert, TextField, InputAdornment, IconButton } from "@mui/material";
import bkstApi from "../../common/api";
import { useNavigate } from "react-router";
import { Link as RouterLink } from "react-router-dom";
import ConfigContext from "../../common/context/ConfigContext";
import { AppStateContext } from "../../common/context/AppStateContext";
import ProductSummary from "../component/ProductSummary";
import { anyWordStartsWith, capitalize } from "../../common/util";
import AvailabilityCalendar from "../component/AvailabilityCalendar";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

export default function Collection() {
  const siteConfig = useContext(ConfigContext);
  const { cart } = useContext(AppStateContext);

  const navigate = useNavigate();

  const [tags, setTags] = useState([]);
  const [categoryMap, setCategoryMap] = useState("");

  const [query, setQuery] = useState("");
  const [filtered, setFiltered] = useState([]);

  const [category, setCategory] = useState("");
  const [products, setProducts] = useState([]);
  const [storeLocation, setStoreLocation] = useState("");

  const [date, setDate] = useState("");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    bkstApi(`/site/${siteConfig.id}/product/collection/shop`)
      .then((res) => {
        let sortedByRank = res.data.products.sort((o1, o2) => {
          let rslt = (+o2.rank || 0) - (+o1.rank || 0);
          if (rslt === 0) {
            rslt = o1.title > o2.title ? 1 : -1;
          }
          return rslt;
        });
        setProducts(sortedByRank);
        setFiltered(sortedByRank);

        let map = {};
        for (const p of sortedByRank) {
          const cat = p.type.toLowerCase();
          const arr = map[cat] || [];
          arr.push(p);
          map[cat] = arr;
        }
        setCategoryMap(map);
        setStoreLocation(res.data.storeLocation);

        setLoading(false);

        if (window.location.hash) {
          setTimeout(() => {
            setCategory(window.location.hash.substring(1));
            scrollToCategory(window.location.hash.substring(1).replace("-", " "));
          }, 500);
        } else {
          window.scrollTo(0, 0);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);

  const scrollToCategory = (cat) => {
    const el = document.getElementById(cat);
    if (el) {
      el.scrollIntoView();
    }
  };

  const getRank = (cat) => {
    if (cat.includes("easter") || cat.includes("ramadan")) return 1;
    if (cat.includes("passover")) return 2;
    if (cat.includes("featured")) return 3;
    if (cat === "cake" || cat === "cakes") return 4;
    return 5; // all other strings
  };

  const sortCategories = (o1, o2) => {
    const rankA = getRank(o1);
    const rankB = getRank(o2);

    if (rankA === rankB) {
      return o1.localeCompare(o2);
    } else {
      return rankA - rankB;
    }
  };

  useEffect(() => {
    setFiltered([]);
    setFiltered(products.filter((o) => anyWordStartsWith(o.title, query) || anyWordStartsWith(o.type, query)));
  }, [query]);

  if (loading) {
    return <LinearProgress />;
  } else {
    return (
      <Box pb={20}>
        {cart?.quantity > 0 && (
          <Box>
            <Alert
              severity="success"
              action={
                <Button color="inherit" size="small" component={RouterLink} to={`/cart/${cart.id}`}>
                  Proceed to Checkout
                </Button>
              }
            >
              You have {cart.quantity} items in your cart
            </Alert>
          </Box>
        )}
        <Box
          sx={{
            backgroundColor: "#ECECEC",
            position: "-webkit-sticky",
            position: "sticky",
            top: "0",
            border: "1px solid #EEE",
            marginBottom: "2rem"
          }}
        >
          <Container maxWidth="lg">
            <Box>
              <Box pt={3}>
                <TextField
                  id="query"
                  value={query}
                  label=""
                  name="query"
                  variant="outlined"
                  fullWidth
                  onChange={(e) => setQuery(e.target.value)}
                  placeholder="Filter items by name or category"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment sx={{ paddingRight: "10px" }}>
                        <FilterListIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment>
                        {query && (
                          <IconButton onClick={(e) => setQuery("")}>
                            <CloseOutlinedIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    )
                  }}
                  sx={{ backgroundColor: "#FFF", borderRadius: "4px" }}
                />
              </Box>
              <Tabs value={false} variant="scrollable" scrollButtons="auto" allowScrollButtonsMobile>
                {Object.keys(categoryMap)
                  .sort(sortCategories)
                  .map((cat, idx) => (
                    <Tab key={idx} label={cat} onClick={() => setQuery(cat)} />
                  ))}
              </Tabs>
            </Box>
          </Container>
        </Box>
        <Container maxWidth="lg">
          <Box>
            {/* <Box
            sx={{
              backgroundImage:
                "linear-gradient(45deg , #ECECEC 10%,transparent 11%, transparent 89% , #ECECEC 90%),linear-gradient(135deg , #ECECEC 10%,transparent 11%, transparent 89% , #ECECEC 90%),radial-gradient(circle, transparent 25%, #FEFEFE  26%),linear-gradient(0deg, transparent 44%, #ECECEC 45%, #ECECEC 55%, transparent 56%), linear-gradient(90deg, transparent 44%, #ECECEC 45%, #ECECEC 55%, transparent 56%)",
              backgroundSize: "2em 2em"
            }}
          >*/}
            <AvailabilityCalendar storeLocation={storeLocation} />
            {query && (
              <Grid container spacing={3}>
                {filtered.map((o, index) => {
                  return <ProductSummary key={index} product={o} />;
                })}
              </Grid>
            )}
            {!query &&
              Object.keys(categoryMap)
                .sort(sortCategories)
                .map((key) => {
                  return (
                    <Box mb={4} id={key} sx={{ scrollMarginTop: "75px" }}>
                      <Box mb={2} textAlign={"left"}>
                        <Typography variant="h4" color="textSecondary">
                          {capitalize(key)}
                        </Typography>
                      </Box>
                      <Grid container spacing={3}>
                        {categoryMap[key].map((product, index) => {
                          return <ProductSummary key={index} product={product} />;
                        })}
                      </Grid>
                    </Box>
                  );
                })}
          </Box>
        </Container>
      </Box>
    );
  }
}
